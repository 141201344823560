import React, { useRef } from 'react';
import {
  motion,
  useInView,
  useScroll,
  useSpring,
  useTransform,
} from 'framer-motion';
import { UIWelcome } from './types';
import Image from '@/components/base/image';
import { useQAPanelContext } from '@/hooks/useQAPanelContext';
import useDeviceType from '@/hooks/useDeviceType';
import MotivationBox from '../motivation-box/MotivationBox';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        duration: 0.1,
      },
    },
  },
  item: {
    hidden: {
      x: -50,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  },
};

const Welcome: React.FC<UIWelcome> = ({
  centerImage,
  children,
  description,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 30,
  mtMobile = 30,
}) => {
  const svgContainerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(svgContainerRef, { once: true });
  const deviceType = useDeviceType();
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: svgContainerRef,
    offset: deviceType === 'mobile' ? ['0 1', '1 1'] : ['0 1', '1 1'],
  });

  const yRange = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 900, damping: 60 });
  const secondPathOpacity = useTransform(scrollYProgress, [0.5, 1], [0, 1]);

  const handleOpenQAPanel = () => {
    const targetButton = document.getElementById('qa-panel-open-btn');
    if (targetButton) {
      targetButton.click();
    }
  };

  return (
    <motion.section
      className="welcome"
      variants={animations.container}
      initial="hidden"
      animate={`${isInView ? 'show' : 'hidden'}`}
      viewport={{ once: true, amount: 0.25 }}
      ref={svgContainerRef}
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      <motion.div
        className="svg-container svg-container-left"
        variants={animations.item}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="964.835"
          height="461.051"
          viewBox="0 0 964.835 461.051"
        >
          <motion.g transform="matrix(0.914, -0.407, 0.407, 0.914, 44.251, -780.963)">
            <motion.path
              d="M140.262-40.02a8.1,8.1,0,0,1-2.733,5.821,18.92,18.92,0,0,1-7.123,4.1,30.333,30.333,0,0,1-9.86,1.519H98a9.069,9.069,0,0,0-3.548.536A8.062,8.062,0,0,0,91.9-25.99L54.444,14.669A5.506,5.506,0,0,1,50.2,16.661H43.423a1.751,1.751,0,0,1-1.591-.852,1.942,1.942,0,0,1,0-1.966l19.284-42.4-28.26-3.217L22.789-13.5a3.97,3.97,0,0,1-3.762,2H16.586a2.433,2.433,0,0,1-1.8-.664,2.418,2.418,0,0,1-.664-1.789v-52.17a2.346,2.346,0,0,1,.664-1.739,2.438,2.438,0,0,1,1.8-.661h2.442a4,4,0,0,1,3.762,1.945l10.07,18.321,28.26-3.217-19.284-42.4a1.947,1.947,0,0,1,0-1.968,1.752,1.752,0,0,1,1.591-.85H50.2a5.506,5.506,0,0,1,4.242,1.992L91.9-54.049A8.022,8.022,0,0,0,94.453-52,9.111,9.111,0,0,0,98-51.465h22.545a30.283,30.283,0,0,1,9.86,1.522,18.947,18.947,0,0,1,7.123,4.1A8.1,8.1,0,0,1,140.262-40.02Z"
              transform="matrix(0.545, 0.839, -0.839, 0.545, 306.587, 1156.039)"
              fill="currentColor"
              style={{
                pathLength,
                fillOpacity: pathLength,
              }}
            />
            <motion.path
              d="M-9966.875,1617.573s-193.87-93.609-341.854,117.62-443.838,201.615-443.838,201.615"
              transform="translate(9779.227 4722.579) rotate(30)"
              fill="none"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeWidth="40"
              style={{
                pathLength,
                fillOpacity: pathLength,
              }}
            />
          </motion.g>
        </motion.svg>
      </motion.div>
      <motion.div className="welcome-space" variants={animations.item} />
      <motion.div variants={animations.item} className="welcome-content">
        <motion.div
          className="welcome-content-container"
          variants={animations.item}
        >
          {children}
        </motion.div>
      </motion.div>
      <motion.div variants={animations.item} className="welcome-motivation">
        <MotivationBox />
      </motion.div>
      <motion.div
        className="svg-container svg-container-right"
        variants={animations.item}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="952.142"
          height="499.721"
          viewBox="0 0 952.142 499.721"
        >
          <motion.g transform="translate(14260.897 2361.28)">
            <motion.path
              d="M-9966.875,1617.573s-193.87-93.609-341.854,117.62-443.838,201.615-443.838,201.615"
              transform="translate(-3226.497 -2236.589) rotate(9)"
              fill="none"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeWidth="40"
              style={{
                pathLength,
                fillOpacity: pathLength,
              }}
            />
            <motion.path
              d="M61.81-1.492a4.962,4.962,0,0,1-1.953-.489,13.262,13.262,0,0,1-2.031-1.1A150.257,150.257,0,0,1,34.652-21.052,79.456,79.456,0,0,1,19.48-40.4a41.905,41.905,0,0,1-5.359-20.1,32.529,32.529,0,0,1,2.057-11.768,28.13,28.13,0,0,1,5.742-9.273,25.583,25.583,0,0,1,8.577-6.04,26.207,26.207,0,0,1,10.516-2.115,21.387,21.387,0,0,1,12.325,3.586,26.638,26.638,0,0,1,8.472,9.453,26.453,26.453,0,0,1,8.454-9.476,21.37,21.37,0,0,1,12.3-3.563A26.171,26.171,0,0,1,93.1-87.579a25.646,25.646,0,0,1,8.554,6.04,28.1,28.1,0,0,1,5.74,9.273A32.562,32.562,0,0,1,109.452-60.5a41.905,41.905,0,0,1-5.359,20.1,79.482,79.482,0,0,1-15.17,19.349A150.157,150.157,0,0,1,65.752-3.085a13.467,13.467,0,0,1-2.035,1.1A4.894,4.894,0,0,1,61.81-1.492Z"
              transform="translate(-14237.729 -2091.066) rotate(103)"
              fill="currentColor"
              style={{
                pathLength,
                fillOpacity: secondPathOpacity,
              }}
            />
          </motion.g>
        </motion.svg>
      </motion.div>
    </motion.section>
  );
};

export default Welcome;
